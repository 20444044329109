import * as api from '../api';
import { PURGE } from 'redux-persist';

const moment = require('moment-timezone');
const dateFormat = 'MMMM D, YYYY h:mm a';

const GET_ACTIVE_RIDE = 'rides/GET_ACTIVE_RIDE';
const GET_USER_RIDES = 'rides/GET_USER_RIDES';
const GET_MAP_RIDES = 'rides/GET_MAP_RIDES';
const REFUND_RIDE = 'rides/REFUND_RIDE';
const REFUND_PARTIAL_RIDE = 'rides/REFUND_PARTIAL_RIDE';
const initialState = [];

export default function reducer(state = initialState, action) {
  switch (action.type) {
    case GET_ACTIVE_RIDE:
      return action.data.map((ride) => {
        const formattedStart = moment(ride.start_time).format(dateFormat);
        const formattedEnd = moment(ride.end_time).format(dateFormat);
        const formattedDuration = `${Math.round(ride.duration / 60)} min`;
        return {
          ...ride,
          formatted_start_time: formattedStart,
          formatted_end_time: formattedEnd,
          formatted_duration: formattedDuration,
        };
      });
    case GET_MAP_RIDES:
      return action.data.map((ride) => {
        // Add formatted dates/durations here so they're available wherever ride data is shown
        const formattedStart = moment(ride.start_time).format(dateFormat);
        const formattedEnd = moment(ride.end_time).format(dateFormat);
        const formattedDuration = `${Math.round(ride.duration / 60)} min`;
        return {
          ...ride,
          formatted_start_time: formattedStart,
          formatted_end_time: formattedEnd,
          formatted_duration: formattedDuration,
        };
    });
    case GET_USER_RIDES:
      return action.data.map((ride) => {
        // Add formatted dates/durations here so they're available wherever ride data is shown
        const formattedStart = moment(ride.start_time).format(dateFormat);
        const formattedEnd = moment(ride.end_time).format(dateFormat);
        const formattedDuration = `${Math.round(ride.duration / 60)} min`;
        return {
          ...ride,
          formatted_start_time: formattedStart,
          formatted_end_time: formattedEnd,
          formatted_duration: formattedDuration,
        };
    });
    case REFUND_RIDE:
      return state;
    case REFUND_PARTIAL_RIDE:
      return state;
    case PURGE:
      return initialState;
    default:
      return state;
  }
}

export const getUserActiveRide = (jwt, fleetIdId, userId) => {
  return (dispatch) => {
    return api
      .getUserActiveRide(jwt, fleetIdId, userId)
      .then(({ ride }) => {
        dispatch({ type: GET_ACTIVE_RIDE, data: ride });});
  };
};

export const getUserRides = (jwt, fleetIdId, userId) => {
  return (dispatch) => {
    return api
      .getUserRides(jwt, fleetIdId, userId)
      .then(({ rides }) => {
        dispatch({ type: GET_USER_RIDES, data: rides });});
  };
};



export const getMapRides = (jwt, fleetIdId, date) => {
  return (dispatch) => {
    return api
      .getMapRides(jwt, fleetIdId, date)
      .then(({ rides }) => dispatch({ type: GET_MAP_RIDES, data: rides }));
  };
};

export const refundRide = (jwt,user_id, params) => {
  return (dispatch) => {
    return api
      .refundRide(jwt, user_id, params)
      .then((response) => {
        if (response) {
          dispatch({ type: REFUND_RIDE, data: null });
        } else {
          throw new Error('There was a problem refunding user');
        }
      })
      .catch((error) => {
        throw error;
      });
  };
};

export const refundPartialRide = (jwt, ride_id, params) => {
  return (dispatch) => {
    return api
      .refundPartial(jwt, ride_id, params)
      .then((response) => {
        if (response) {
          dispatch({ type: REFUND_PARTIAL_RIDE, data: null });
        } else {
          throw new Error('There was a problem refunding user');
        }
      })
      .catch((error) => {
        throw error;
      });
  };
};



