import * as api from '../api';
import { PURGE } from 'redux-persist';

const GET_VEHICLE_FARES = 'fares/GET_VEHICLE_FARES';


const initialState = [];

export default function reducer(state = initialState, action) {
  switch (action.type) {
    case GET_VEHICLE_FARES:
      return action.data;
    case PURGE:
      return initialState;
    default:
      return state;
  }
}

export const getFares = (jwt, fleetId, vehicleType) => {
  return (dispatch) => {
    return api
      .getVehicleFares(jwt, fleetId, vehicleType)
      .then(({ fares }) => dispatch({ type: GET_VEHICLE_FARES, data: fares }));
  };
};


