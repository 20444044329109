import * as api from '../api';

const POST_GEOFENCE = 'geofences/POST_GEOFENCE';
const DELETE_GEOFENCE = 'geofences/DELETE_GEOFENCE';
const PATCH_GEOFENCE = 'geofences/PATCH_GEOFENCE';
const initialState = [];

export default function reducer(state = initialState, action) {
  switch (action.type) {
    case POST_GEOFENCE:
      return state;
    case DELETE_GEOFENCE:
      return state;
    case PATCH_GEOFENCE:
      return state;
    case 'PURGE':
      return initialState;
    default:
      return state;
  }
}

export const postGeofence = (jwt, fleetId, params) => {
  return async (dispatch) => {
    return api
      .postGeofence(jwt, fleetId, params)
      .then(({ geofence }) => dispatch({ type: POST_GEOFENCE, data: geofence }));
  };
};

export const deleteGeofence = (jwt, geofence_id) => {
  return (dispatch) => {
    return api
      .deleteGeofence(jwt, geofence_id)
      .then((geofence) => {
        if (geofence) {
          dispatch({ type: DELETE_GEOFENCE, data: null });
        } else {
          throw new Error('There was a problem trying to get Fleet data');
        }
      })
      .catch((error) => {
        throw error;
      });
  };
};

export const patchGeofence = (jwt, geofence_id, params) => {
  return (dispatch) => {
    return api
      .patchGeofence(jwt, geofence_id, params)
      .then((geofence) => {
        if (geofence) {
          dispatch({ type: PATCH_GEOFENCE, data: null });
        } else {
          throw new Error('There was a problem trying to patch restricted area');
        }
      })
      .catch((error) => {
        throw error;
      });
  };
};












