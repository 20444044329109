import * as api from '../api';
import { PURGE } from 'redux-persist';
import { API_ERROR } from './error';
const GET_FLEET_PASSKEYS = 'fleetPasskeys/GET_PASSKEYS';
const GET_CANCELLED_FLEET_PASSKEYS = 'fleetPasskeys/GET_CANCELLED_PASSKEYS';
const UPDATE_FLEET_PASSKEY = 'fleetPasskeys/UPDATE_PASSKEY';
const TOGGLE_PASSKEY_ACTIVE = 'fleetPasskeys/TOGGLE_PASSKEY_ACTIVE';
const initialState = {
  active: [],
  cancelled: [],
};

export default function reducer(state = initialState, action) {

    //console.log(`reducer [${JSON.stringify(state)}] [${JSON.stringify(action)}]`);
  switch (action.type) {
    case GET_FLEET_PASSKEYS:
      return  action.data;
    case GET_CANCELLED_FLEET_PASSKEYS:
      return Object.assign({}, state, { cancelled: action.data });
    case UPDATE_FLEET_PASSKEY:
      return state;
    case TOGGLE_PASSKEY_ACTIVE: 
        return state;
    case PURGE:
      return initialState;
    default:
      return state;
  }
}

export const getPasskeys = (jwt, fleetId) => {
  return (dispatch) => {
    return api
      .getPasskeys(jwt, fleetId)
      .then((passkeys) => {
        //console.log(`getPasskeys data [${JSON.stringify(passkeys)}]`);
        dispatch({ type: GET_FLEET_PASSKEYS, data: passkeys });
      });
  };
};

export const updatePasskey = (jwt, fleetId, passkeyId, newParams) => {
  return (dispatch) => {
    return api.patchPasskey(jwt, fleetId, passkeyId, newParams).then(({ passkey, error }) => {
      //console.log('error conditions:', error);
      passkey
        ? dispatch({ type: UPDATE_FLEET_PASSKEY, data: passkey })
        : dispatch({ type: API_ERROR, error });
    });
  };
};

export const addPasskey = (jwt, fleetId, passkeyData) => {
  return (dispatch) => {
    return api
      .addNewPasskey(jwt, fleetId, passkeyData)
      .then(({ success, error }) => {
        if (success) {
          return api.getPasskeys(jwt, fleetId);
        } else {
          dispatch({ type: API_ERROR, error });
          return { error };
        }
      });
  };
};

export const deletePasskey = (jwt, fleetId, passkeyId) => {
  return (dispatch) => {
    return api
      .deletePasskey(jwt, fleetId, passkeyId)
      .then(({ success, error }) =>
        success ? api.getPasskeys(jwt, fleetId) : dispatch({ type: API_ERROR, error })
      );
  };
};

export const togglePasskeyActive = (jwt, fleetId, passkeyId, isActive) => {
  return (dispatch) => {
    // Optimistically assume the API call will succeed
    dispatch({ type: TOGGLE_PASSKEY_ACTIVE, data: passkeyId });
    // Make the API call
    return api.togglePasskeyActive(jwt, fleetId, passkeyId, isActive).catch((error) => {
      // If it failed, roll back the optimistic change
      dispatch({ type: TOGGLE_PASSKEY_ACTIVE, data: passkeyId });
    });
  };
};
