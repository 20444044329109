import * as api from '../api';
import { PURGE } from 'redux-persist';

const GET_VEHICLES = 'vehicles/GET_VEHICLES';
const REMOVE_VEHICLE = 'vehicles/REMOVE_VEHICLE';
const LOCK_VEHICLE = 'vehicles/LOCK_VEHICLE';
const PATCH_VEHICLE = 'vehicles/PATCH_VEHICLE';
const UNLOCK_VEHICLE = 'vehicles/UNLOCK_VEHICLE';

const initialState = [];

export default function reducer(state = initialState, action) {
  switch (action.type) {
    case GET_VEHICLES:
      return action.data;
    case REMOVE_VEHICLE:
      return state.filter((v) => v.hash !== action.data);
    case LOCK_VEHICLE:
      return state.map(v => v.hash === action.data ? { ...v, isLocked: true } : v);
    case UNLOCK_VEHICLE:
      return state.map(v => v.hash === action.data ? { ...v, isLocked: false } : v);
    case PATCH_VEHICLE:
      return state.map(v => v.hash === action.data ? { ...v, isLocked: false } : v);
    case PURGE:
      return initialState;
    default:
      return state;
  }
}

export const getVehicles = (jwt, fleetId) => {
  return (dispatch) => {
    return api
      .getVehicles(jwt, fleetId)
      .then(({ vehicles }) => dispatch({ type: GET_VEHICLES, data: vehicles }));
  };
};

export const removeVehicle = (jwt, fleetId, vehicleHash) => {
  return (dispatch) => {
    return api
      .removeVehicle(jwt, fleetId, vehicleHash)
      .then(() => dispatch({ type: REMOVE_VEHICLE, data: vehicleHash }));
  };
};

export const lockVehicle = (jwt, vehicleHash) => {
  return (dispatch) => {
    return api.lockVehicle(jwt, vehicleHash)
      .then(() => {
        dispatch({ type: LOCK_VEHICLE, data: vehicleHash });
      })
      .catch(error => {
        console.error('Locking failed:', error.message);
        throw error;
      });
  };
};

export const patchVehicle = (jwt, vehicleHash,  params) => {
  return (dispatch) => {
    return api
      .patchVehicle(jwt, vehicleHash, params)
      .then((vehicle) => {
        if (vehicle) {
          dispatch({ type: PATCH_VEHICLE, data: vehicleHash });
        } else {
          throw new Error('There was a problem trying to patch vehicle');
        }
      })
      .catch((error) => {
        throw error;
      });
  };
};

export const unlockVehicle = (jwt, vehicleHash) => {
  return (dispatch) => {
    return api.unlockVehicle(jwt, vehicleHash)
      .then(() => {
        dispatch({ type: LOCK_VEHICLE, data: vehicleHash });
      })
      .catch(error => {
        console.error('Unlocking failed:', error.message);
        throw error;
      });
  };
};
