import * as api from '../api';
import { PURGE } from 'redux-persist';

const GET_FLEET_FEATURES = 'fleets/GET_FLEET_FEATURES';
const initialState = {
  fleetFeatures: {
    restrictedAreas: [],
  },
};

export default function reducer(state = initialState, action) {
  switch (action.type) {
    case GET_FLEET_FEATURES:
      return action.data;
    case PURGE:
      return initialState;
    default:
      return state;
  }
}

export const getFleetFeatures = (jwt, fleetId) => {
  return (dispatch) => {
    return api
      .getFleetFeatures(jwt, fleetId)
      .then((features ) => {
        if (features) {
          dispatch({ type: GET_FLEET_FEATURES, data: features });
        } else {
          throw new Error('There was a problem trying to get zone feature data');
        }
  });
  };
};

