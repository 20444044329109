import * as api from '../api';
import { PURGE } from 'redux-persist';
import { API_ERROR } from './error';
const GET_FLEET_USERS = 'fleetUsers/GET_USERS';
const GET_CANCELLED_FLEET_USERS = 'fleetUsers/GET_CANCELLED_USERS';
const UPDATE_FLEET_USER = 'fleetUsers/UPDATE_USER';
const TOGGLE_USER_ACTIVE = 'fleetUsers/TOGGLE_USER_ACTIVE';
const TOGGLE_GROUP_RIDES = 'fleetUsers/TOGGLE_GROUP_RIDES';
const initialState = {
  active: [],
  cancelled: [],
};

export default function reducer(state = initialState, action) {
  switch (action.type) {
    case GET_FLEET_USERS:
      return Object.assign({}, state, { active: action.data });
    case GET_CANCELLED_FLEET_USERS:
      return Object.assign({}, state, { cancelled: action.data });
    case UPDATE_FLEET_USER:
      const updatedUser = action.data;
      let active = [...state.active];
      const index = active.findIndex((user) => user.id === updatedUser.id);
      active[index] = updatedUser;
      return Object.assign({}, state, { active });
    case TOGGLE_USER_ACTIVE: {
      const userIdToToggle = action.data;
      const activeUsers = state.active.map((user) => {
        if (user.id === userIdToToggle) {
          user.is_active = !user.is_active;
        }
        return user;
      });
      return Object.assign({}, state, { active: activeUsers });
    }
    case TOGGLE_GROUP_RIDES: {
      const userIdToToggle = action.data;
      const activeUsers = state.active.map((user) => {
        if (user.id === userIdToToggle) {
          user.has_group_rides = !user.has_group_rides;
        }
        return user;
      });
      return Object.assign({}, state, { active: activeUsers });
    }
    case PURGE:
      return initialState;
    default:
      return state;
  }
}

export const getUsers = (jwt, fleetId) => {
  return (dispatch) => {
    return api
      .getUsers(jwt, fleetId)
      .then(({ users }) => dispatch({ type: GET_FLEET_USERS, data: users }));
  };
};

export const getCancelledUsers = (jwt, fleetId) => {
  return (dispatch) => {
    return api
      .getUsers(jwt, fleetId, true)
      .then(({ users }) => dispatch({ type: GET_CANCELLED_FLEET_USERS, data: users }));
  };
};

export const updateUser = (jwt, fleetId, userId, newParams) => {
  return (dispatch) => {
    return api.patchUser(jwt, fleetId, userId, newParams).then(({ user, error }) => {
      console.log('error conditions:', error);
      user
        ? dispatch({ type: UPDATE_FLEET_USER, data: user })
        : dispatch({ type: API_ERROR, error });
    });
  };
};

export const addUser = (jwt, fleetId, userData) => {
  return (dispatch) => {
    return api
      .addNewUser(jwt, fleetId, userData)
      .then(({ success, error }) => {
        if (success) {
          return api.getUsers(jwt, fleetId);
        } else {
          dispatch({ type: API_ERROR, error });
          return { error };
        }
      });
  };
};

export const deleteUser = (jwt, fleetId, userId) => {
  return (dispatch) => {
    return api
      .deleteUser(jwt, fleetId, userId)
      .then(({ success, error }) =>
        success ? api.getUsers(jwt, fleetId) : dispatch({ type: API_ERROR, error })
      );
  };
};

export const toggleUserActive = (jwt, fleetId, userId, isActive) => {
  return (dispatch) => {
    // Optimistically assume the API call will succeed
    dispatch({ type: TOGGLE_USER_ACTIVE, data: userId });
    // Make the API call
    return api.toggleUserActive(jwt, fleetId, userId, isActive).catch((error) => {
      // If it failed, roll back the optimistic change
      dispatch({ type: TOGGLE_USER_ACTIVE, data: userId });
    });
  };
};

export const toggleGroupRides = (jwt, fleetId, userId, hasGroupRides) => {
  return (dispatch) => {
    // Optimistically assume the API call will succeed
    dispatch({ type: TOGGLE_GROUP_RIDES, data: userId });
    // Make the API call
    return api.toggleGroupRides(jwt, fleetId, userId, hasGroupRides).catch((error) => {
      // If it failed, roll back the optimistic change
      dispatch({ type: TOGGLE_GROUP_RIDES, data: userId });
    });
  };
};
