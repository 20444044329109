import * as api from '../api';
import { PURGE } from 'redux-persist';

const moment = require('moment-timezone');
const dateFormat = 'MMMM D, YYYY h:mm a';

const GET_ACTIVE_RIDE = 'ride/GET_ACTIVE_RIDE';
const END_RIDE = 'ride/END_RIDE';
const START_RIDE = 'ride/START_RIDE';
const initialState = {
  rides: []
};

export default function reducer(state = initialState, action) {
  switch (action.type) {
    case GET_ACTIVE_RIDE:
      return action.data.map((ride) => {
        const formattedStart = moment(ride.start_time).format(dateFormat);
        const formattedEnd = moment(ride.end_time).format(dateFormat);
        const formattedDuration = `${Math.round(ride.duration / 60)} min`;
        return {
          ...ride,
          formatted_start_time: formattedStart,
          formatted_end_time: formattedEnd,
          formatted_duration: formattedDuration,
        };
      });
    case END_RIDE:
      return initialState;
    case START_RIDE:
      return initialState;
    case PURGE:
      return initialState;
    default:
      return state;
  }
}


export const endRide = (token, rideId, params) => {
  return (dispatch) => {
    return api
      .endRide(token, rideId, params)
      .then((response) => {
        if (response) {
          dispatch({ type: END_RIDE });
        } else {
          throw new Error('There was a problem trying to end ride');
        }
      })
      .catch((error) => {
        throw error;
      });
  };
};

export const startRide = (token,params) => {
  return (dispatch) => {
    return api
      .startRide(token, params)
      .then((response) => {
        if (response) {
          dispatch({ type: END_RIDE });
        } else {
          throw new Error('There was a problem trying to start ride');
        }
      })
      .catch((error) => {
        throw error;
      });
  };
};

export const getUserActiveRide = (jwt, fleetIdId, userId) => {
  return (dispatch) => {
    return api
      .getUserActiveRide(jwt, fleetIdId, userId)
      .then(({ ride }) => {
        dispatch({ type: GET_ACTIVE_RIDE, data: ride });});
  };
};






