import * as api from '../api';
import { PURGE } from 'redux-persist';

const GET_DASHBOARD = 'dashboard/GET_DASHBOARD';
const initialState = [];

export default function reducer(state = initialState, action) {
  switch (action.type) {
    case GET_DASHBOARD:
      return action.data || initialState; // Ensure state is never undefined
    case 'PURGE': // Add this case if you are using the 'PURGE' action elsewhere
      return initialState;
    default:
      return state;
  }
}

export const getDashboard = (jwt, fleetId) => {
  return (dispatch) => {
    return api.getDashboard(jwt, fleetId)
      .then((dashboard) => {
        console.log('dashboard dispatch:', dashboard);
        dispatch({ type: GET_DASHBOARD, data: dashboard });
      })
      .catch((error) => {
        console.error(error);
      });
  };
};









