import * as api from '../api';
import { PURGE } from 'redux-persist';

const GET_FLEETS = 'fleets/GET_FLEETS';
const SELECT_FLEET = 'fleets/SELECT_FLEET';
const initialState = [];

export default function reducer(state = initialState, action) {
  switch (action.type) {
    case GET_FLEETS:
      // Keep the selected fleet, if one has been selected; otherwise, default to the first
      const selectedFleet = state.find(({ selected }) => selected === true);
      if (selectedFleet) {
        action.data.forEach((fleet) => (fleet.selected = fleet.id === selectedFleet.id));
      } else {
        action.data.forEach((fleet, index) => (fleet.selected = index === 0));
      }
      return [...action.data];
    case SELECT_FLEET:
      const newState = [...state];
      newState.forEach((fleet) => (fleet.selected = fleet.id === action.data));
      return [...newState];
    case PURGE:
      return initialState;
    default:
      return state;
  }
}

export const getFleets = (jwt) => {
  return (dispatch) => {
    return api.getFleets(jwt).then(({ fleets }) => dispatch({ type: GET_FLEETS, data: fleets }));
  };
};

export const selectFleet = (fleetId) => {
  return (dispatch) => dispatch({ type: SELECT_FLEET, data: fleetId });
};
