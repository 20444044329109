import * as api from '../api';
import { PURGE } from 'redux-persist';

const moment = require('moment-timezone');
const dateFormat = 'MMMM D, YYYY h:mm a';

const GET_FINANCIAL_REPORT = 'financialReport/GET_FINANCIAL_REPORT';
const GET_FINANCIAL_REPORT_TOTALS = 'financialReport/GET_FINANCIAL_REPORT_TOTALS';

// Refactor initial state to accommodate both report and totals
const initialState = {
  report: [],
  totals: {}
};

export default function reducer(state = initialState, action) {
  switch (action.type) {
    case GET_FINANCIAL_REPORT:
      const report = action.data.map((ride) => {
        const formattedStart = moment(ride.start_time).format(dateFormat);
        return {
          ...ride,
          formatted_start_time: formattedStart,
        };
      });
      return { ...state, report }; // Update only the report part of the state

    case GET_FINANCIAL_REPORT_TOTALS:
      const totals = action.data; // Assuming 'data' contains the totals
      return { ...state, totals }; // Update only the totals part of the state

    case PURGE:
      return initialState;

    default:
      return state;
  }
}

export const getFinancialReport = (jwt, fleet_id, startDate, endDate) => {
  return (dispatch) => {
    return api
      .getFinancialReport(jwt, fleet_id, startDate, endDate)
      .then((data) => { // 'data' will be the JSON response
        dispatch({ type: GET_FINANCIAL_REPORT, data: data }); // Dispatch the actual data
      })
  };
};

export const getFinancialReportTotals = (jwt, fleet_id, startDate, endDate) => {
  return (dispatch) => {
    return api
      .getFinancialReportTotals(jwt, fleet_id, startDate, endDate)
      .then((data) => { 
        dispatch({ type: GET_FINANCIAL_REPORT_TOTALS, data: data }); 
      })
  };
};


