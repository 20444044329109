import * as api from '../api';
import { PURGE } from 'redux-persist';

const CLEAR_USER = 'user/CLEAR_USER';
const GET_TOKEN = 'user/GET_TOKEN';
const initialState = null;

export default function reducer(state = initialState, action) {
  switch (action.type) {
    case GET_TOKEN:
      return action.data;
    case PURGE:
    case CLEAR_USER:
      return initialState;
    default:
      return state;
  }
}

export const login = (email, password) => {
  return (dispatch) => {
    return api
      .login(email, password)
      .then(({ user, token }) => dispatch({ type: GET_TOKEN, data: { user, token } }));
  };
};

export const clearUser = () => {
  return (dispatch) => dispatch({ type: PURGE, result: () => null });
};
