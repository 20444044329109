import { createTheme } from '@mui/material/styles';

const theme = createTheme({
  palette: {
    primary: {
      main: '#4CAF50',
      dark: '#006400'
    },
    secondary: {
      main: '#dc004e',
      dark: '#006400'
    },
    dark: {
      main: '#000000',
      lighter: '#333333'
    },
    error: {
      main: '#f44336',
    },
    background: {
      default: '#f5f5f5',
    },
  },
  typography: {
    fontFamily: '"Raleway","Roboto", "Helvetica", "Arial", sans-serif',
    h1: {
      fontSize: '2.5rem',
    },
    h2: {
      fontSize: '2rem',
    },
    body1: {
      fontSize: '1rem',
    },
  },
  components: {
    MuiButton: {
      styleOverrides: {
        root: {
          borderRadius: '50px',
          textTransform: 'none',
        },
      },
    },
    MuiSwitch: {
      styleOverrides: {
        switchBase: {
          color: '#b0b0b0', // Light grey when switch is off
          '&.Mui-checked': {
            color: '#4caf50', // Green when switch is on
          },
        },
        track: {
          backgroundColor: '#b0b0b0', // Light grey track when switch is off
          '&.Mui-checked': {
            backgroundColor: '#4caf50', // Green track when switch is on
          },
        },
        colorPrimary: {
          '&.Mui-checked + .MuiSwitch-track': {
            backgroundColor: '#4caf50', // Ensures the track is green when checked
          },
        },
      },
    },
  },
});

export default theme;
