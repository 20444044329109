import { PURGE } from 'redux-persist';

export const API_ERROR = 'error/API_ERROR';
export const CLEAR_API_ERROR = 'error/CLEAR_API_ERROR';
const initialState = null;

export default function reducer(state = initialState, action) {
  switch (action.type) {
    case API_ERROR:
      return action.error;
    case PURGE:
    case CLEAR_API_ERROR:
      return null;
    default:
      return state;
  }
}

export const clearError = () => (dispatch) => dispatch({ type: CLEAR_API_ERROR });
