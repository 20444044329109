import * as api from '../api';
import { PURGE } from 'redux-persist';

const GET_ADMINS = 'admins/GET_ADMINS';
const GET_ADMIN_ACCOUNT_TYPES = 'admins/GET_ADMIN_ACCOUNT_TYPES';
const POST_ADMIN = 'admins/POST_ADMIN';
const initialState = { admins: [], account_types: [], new_admin: null };

export default function reducer(state = initialState, action) {
  switch (action.type) {
    case GET_ADMINS:
      return Object.assign({}, state, { admins: action.data, new_admin: null });
    case GET_ADMIN_ACCOUNT_TYPES:
      return Object.assign({}, state, { account_types: action.data });
    case POST_ADMIN:
      return Object.assign({}, state, { new_admin: action.data });
    case PURGE:
      return initialState;
    default:
      return state;
  }
}

export const getAdminAccountTypes = () => {
  return (dispatch) => {
    return api
      .getAdminAccountTypes()
      .then(({ account_types }) =>
        dispatch({ type: GET_ADMIN_ACCOUNT_TYPES, data: account_types })
      );
  };
};

export const getAdmins = (jwt, fleetId) => {
  return (dispatch) => {
    return api
      .getAdmins(jwt, fleetId)
      .then(({ admins }) => dispatch({ type: GET_ADMINS, data: admins }));
  };
};

export const postAdmin = (jwt, fleetId, newAdmin) => {
  return (dispatch) => {
    return api
      .postAdmin(jwt, fleetId, newAdmin)
      .then(({ admin }) => dispatch({ type: POST_ADMIN, data: admin }));
  };
};
