//import { createStore, applyMiddleware } from 'redux';
import { persistStore, persistReducer } from 'redux-persist';
//import storage from 'redux-persist/lib/storage';
import { thunk } from 'redux-thunk';
import logger from 'redux-logger';

import { configureStore } from '@reduxjs/toolkit'

import AsyncStorage from '@react-native-async-storage/async-storage';

import reducers from './state';

const persistConfig = {
  key: 'root',
  storage: AsyncStorage,
  whitelist: ['fleets', 'user'],
};

const persistedReducer = persistReducer(persistConfig, reducers);

//const middleware =
//  process.env.NODE_ENV === 'development' ? [thunk, logger] : [thunk];

// createStore gets called twice (I think due to SSR), so we need to make sure that persistor
// is initialized only once, because otherwise the Redux store gets persisted/rehydrated
// multiple times (e.g. when reloading a page), and the Redux state is lost.
let persistor;

const preloadedState = (preloadedState = {}) => {
    //const store = createStore(persistedReducer, preloadedState, middleware);

    const store = configureStore({
      reducer: persistedReducer,
      middleware: () => { return  process.env.NODE_ENV === 'development' ? [thunk, logger] : [thunk];},
      prelaodedState: preloadedState
    });
  
    if (!persistor) {
      persistor = persistStore(store);
    }
    return { store, persistor };

};

export default preloadedState;


