const  GATSBY_RAZOR_API_URL = process.env.GATSBY_RAZOR_API_URL;
console.log('GATSBY_RAZOR_API_URL:', GATSBY_RAZOR_API_URL);
const API_HOST_V2 = `${GATSBY_RAZOR_API_URL}/v2`;
const API_HOST_V3 = `${GATSBY_RAZOR_API_URL}/v3`;

export const login = (email, password) => {
  const url = `${API_HOST_V2}/fleets/admins/token`;
  const body = {
    email,
    password,
  };
  return fetch(url, {
    method: 'POST',
    body: JSON.stringify(body),
    headers: {
      'Content-Type': 'application/JSON',
    },
  }).then((response) => {
    if (response.ok) {
      return response.json();
    } else {
      switch (response.status) {
        case 404:
          throw new Error('User not found');
        case 401:
          throw new Error('Incorrect username or password');
        default:
          throw new Error('There was a problem with your request. Please try again later');
      }
    }
  });
};

export const getFinancialReport = (jwt, fleetId, startDate, endDate) => {
  const url = `${API_HOST_V2}/fleets/${fleetId}/financial-report?`
   + new URLSearchParams({
    startDate: startDate,
    endDate: endDate
  });
  return fetch(url, {
    headers: {
      'Content-Type': 'application/JSON',
      Authorization: `Bearer ${jwt}`,
    },
  })
  .then((response) => {
    if (response.ok) {
      return response.json(); // Make sure to return the parsed JSON
    } else {
      throw new Error('There was a problem with your request. Please try again later');
    }
  })
  .catch((error) => {
    console.error(error);
    throw error;
  });
};

export const getFinancialReportTotals = (jwt, fleetId, startDate, endDate) => {
  const url = `${API_HOST_V2}/fleets/${fleetId}/financial-report-totals?`
   + new URLSearchParams({
    startDate: startDate,
    endDate: endDate
  });
  return fetch(url, {
    headers: {
      'Content-Type': 'application/JSON',
      Authorization: `Bearer ${jwt}`,
    },
  })
  .then((response) => {
    if (response.ok) {
      return response.json(); // Make sure to return the parsed JSON
    } else {
      throw new Error('There was a problem with your request. Please try again later');
    }
  })
  .catch((error) => {
    console.error(error);
    throw error;
  });
};


export const getFleets = (jwt) => {
  const url = `${API_HOST_V2}/fleets/`;
  return fetch(url, {
    method: 'GET',
    headers: {
      Authorization: `Bearer ${jwt}`,
    },
  }).then((response) => {
    if (response.ok) {
      return response.json();
    } else {
      throw new Error('There was a problem with your request. Please try again later');
    }
  });
};

export const getFleetReservations = (jwt, fleetId, month, year) => {
  let url = `${API_HOST_V2}/fleets/${fleetId}/reservations`;
  if (month !== undefined && year !== undefined) {
    url += `?${new URLSearchParams({ month, year })}`;
  }
  return fetch(url, {
    headers: {
      'Content-Type': 'application/JSON',
      Authorization: `Bearer ${jwt}`,
    },
  })
  .then((response) => {
    if (response.ok) {
      return response.json(); // Make sure to return the parsed JSON
    } else {
      throw new Error('There was a problem with your request. Please try again later');
    }
  })
  .catch((error) => {
    console.error(error);
    throw error;
  });
};

export const getDayFleetReservations = (jwt, fleetId, day) => {
  let url = `${API_HOST_V2}/fleets/${fleetId}/reservations`;
  url += `?${new URLSearchParams({ day })}`;

  return fetch(url, {
    headers: {
      'Content-Type': 'application/JSON',
      Authorization: `Bearer ${jwt}`,
    },
  })
  .then((response) => {
    if (response.ok) {
      return response.json(); // Make sure to return the parsed JSON
    } else {
      throw new Error('There was a problem with your request. Please try again later');
    }
  })
  .catch((error) => {
    console.error(error);
    throw error;
  });
};

export const getVehicles = (jwt, fleetId) => {
  const url = `${API_HOST_V2}/fleets/${fleetId}/vehicles`;
  return fetch(url, {
    method: 'GET',
    headers: {
      Authorization: `Bearer ${jwt}`,
    },
  }).then((response) => {
    if (response.ok) {
      return response.json();
    } else {
      throw new Error('There was a problem with your request. Please try again later');
    }
  });
};

export const getMessages = (jwt, fleetId) => {
  const url = `${API_HOST_V2}/fleets/${fleetId}/messages`;
  return fetch(url, {
    method: 'GET',
    headers: {
      Authorization: `Bearer ${jwt}`,
    },
  }).then((response) => {
    if (response.ok) {
      return response.json();
    } else {
      throw new Error('There was a problem with your request. Please try again later');
    }
  });
};

export const getWaivers = (jwt, operatorId) => {
  const url = `${API_HOST_V3}/operators/${operatorId}/waivers`;
  return fetch(url, {
    method: 'GET',
    headers: {
      Authorization: `Bearer ${jwt}`,
    },
  }).then((response) => {
    if (response.ok) {
      return response.json();
    } else {
      throw new Error('There was a problem with your request. Please try again later');
    }
  });
};

export const removeVehicle = (jwt, fleetId, vehicleHash) => {
  const url = `${API_HOST_V2}/fleets/${fleetId}/vehicles/${vehicleHash}`;
  return fetch(url, {
    method: 'DELETE',
    headers: {
      Authorization: `Bearer ${jwt}`,
    },
  }).then((response) => {
    if (response.ok) {
      return true;
    } else {
      throw new Error('There was a problem with your request. Please try again later');
    }
  });
};

export const addVehicles = (jwt, fleetId, vehicleNames) => {
  const url = `${API_HOST_V2}/fleets/${fleetId}/vehicles`;
  const body = { names: vehicleNames };
  return fetch(url, {
    method: 'POST',
    body: JSON.stringify(body),
    headers: {
      'Content-Type': 'application/JSON',
      Authorization: `Bearer ${jwt}`,
    },
  });
};

export const updateWaiverStatus = (jwt, waiverId, waiverStatus) => {
  const url = `${API_HOST_V2}/users/waiver/${waiverId}/status`;
  const body = { waiver_status: waiverStatus };
  return fetch(url, {
    method: 'POST',
    body: JSON.stringify(body),
    headers: {
      'Content-Type': 'application/JSON',
      Authorization: `Bearer ${jwt}`,
    },
  });
};

export const postGeofence = (jwt, fleetId, params) => {
  const url = `${API_HOST_V2}/fleets/${fleetId}/geofence`;
  return fetch(url, {
    method: 'POST',
    body: JSON.stringify(params),
    headers: {
      'Content-Type': 'application/JSON',
      Authorization: `Bearer ${jwt}`,
    },
  }).then(response => {
    if (!response.ok) {
      return response.json().then(errorJson => {
        throw new Error(errorJson.message || 'Failed to create Geofence');
      });
    }
    return response.json(); // assuming your API returns JSON
  });
};

export const lockVehicle = (jwt, vehicle_hash) => {
  const url = `${API_HOST_V2}/fleets/vehicles/${vehicle_hash}/lock`;
  return fetch(url, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/JSON',
      Authorization: `Bearer ${jwt}`,
    },
  }).then(response => {
    if (!response.ok) {
      return response.json().then(errorJson => {
        throw new Error(errorJson.message || 'Failed to lock vehicle');
      });
    }
    return response.json(); // assuming your API returns JSON
  });
};


export const unlockVehicle = (jwt, vehicle_hash) => {
  const url = `${API_HOST_V2}/fleets/vehicles/${vehicle_hash}/unlock`;
  return fetch(url, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/JSON',
      Authorization: `Bearer ${jwt}`,
    },
  }).then(response => {
    if (!response.ok) {
      return response.json().then(errorJson => {
        throw new Error(errorJson.message || 'Failed to unlock vehicle');
      });
    }
    return response.json(); // assuming your API returns JSON
  });
};



export const postPushMessage = (jwt, fleetId, message) => {
  const url = `${API_HOST_V2}/fleets/${fleetId}/push-notifications`;
  return fetch(url, {
    method: 'POST',
    body: JSON.stringify(message),
    headers: {
      'Content-Type': 'application/JSON',
      Authorization: `Bearer ${jwt}`,
    },
  });
};

export const postAppMessage = (jwt, fleetId, message) => {
  const url = `${API_HOST_V2}/fleets/${fleetId}/app-notification`;
  return fetch(url, {
    method: 'POST',
    body: JSON.stringify(message),
    headers: {
      'Content-Type': 'application/JSON',
      Authorization: `Bearer ${jwt}`,
    },
  });
};

export const getUsers = (jwt, fleetId, cancelled = false) => {
  const url = `${API_HOST_V2}/fleets/${fleetId}/users?cancelled=${cancelled}`;
  return fetch(url, {
    headers: {
      'Content-Type': 'application/JSON',
      Authorization: `Bearer ${jwt}`,
    },
  })
    .then((response) => {
      if (response.ok) {
        return response.json();
      } else {
        throw new Error('There was a problem with your request. Please try again later');
      }
    })
    .catch((error) => {
      console.log(error);
      throw error;
    });
};

export const patchUser = (jwt, fleetId, userId, newParams) => {
  const url = `${API_HOST_V2}/fleets/${fleetId}/users/${userId}`;
  return fetch(url, {
    method: 'PATCH',
    headers: {
      'Content-Type': 'application/JSON',
      Authorization: `Bearer ${jwt}`,
    },
    body: JSON.stringify(newParams),
  })
    .then((response) => {
      if (response.ok) {
        return response.json();
      } else if (response.status === 409) {
        throw new Error('This Phone number is already being used by another user');
      } else {
        throw new Error('There was a problem with your request. Please try again later');
      }
    })
    .catch((error) => {
      console.log(error);
      throw error;
    });
};

export const addNewUser = (jwt, fleetId, userData) => {
  const url = `${API_HOST_V2}/fleets/${fleetId}/users`;
  const body = {
    first_name: userData.firstName,
    last_name: userData.lastName,
    phone: userData.phoneNumber,
    has_group_rides: userData.hasGroupRides,
  };
  userData.startDate && (body.start_date = userData.startDate.toISOString());
  userData.endDate && (body.end_date = userData.endDate.toISOString());

  return fetch(url, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/JSON',
      Authorization: `Bearer ${jwt}`,
    },
    body: JSON.stringify(body),
  }).then((response) => {
    return response.json().then((json) => {
      if (response.ok) {
        return { success: true };
      } else {
        return { success: false, error: json.message || 'Unknown error occurred' };
      }
    });
  });
};

export const deleteUser = (jwt, fleetId, userId) => {
  const url = `${API_HOST_V2}/fleets/${fleetId}/users/${userId}`;
  return fetch(url, {
    method: 'DELETE',
    headers: {
      'Content-Type': 'application/JSON',
      Authorization: `Bearer ${jwt}`,
    },
  }).then((response) => {
    if (response.ok) {
      return { success: true };
    } else {
      return response.json().then((json) => ({ success: false, error: json }));
    }
  });
};

export const forgotPassword = (email) => {
  const url = `${API_HOST_V2}/fleets/admins/forgot-password`;
  return fetch(url, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/JSON',
    },
    body: JSON.stringify({
      email,
    }),
  }).then((response) => {
    return response.json().then((json) => ({ success: response.ok, response: json }));
  });
};

export const resetPassword = (adminId, token, password) => {
  const url = `${API_HOST_V2}/fleets/admins/${adminId}/reset-password`;
  return fetch(url, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/JSON',
    },
    body: JSON.stringify({
      token,
      password,
    }),
  })
    .then((response) => {
      return response.json().then((json) => {
        return { success: response.ok, response: json };
      });
    })
    .catch((error) => {
      return { success: false, response: error };
    });
};

export const deleteGeofence = (jwt, geofenceId) => {
  const url = `${API_HOST_V2}/fleets/geofence/${geofenceId}`;
  return fetch(url, {
    method: 'DELETE',
    headers: {
      Authorization: `Bearer ${jwt}`,
    },
  }).then((response) => {
    if (response.ok) {
      return response.text().then((text) => {
        return text ? JSON.parse(text) : {};
      });
    } else {
      throw new Error('There was a problem with your request. Please try again later');
    }
  });
};

export const patchGeofence = (jwt, geofenceId, params) => {
  const url = `${API_HOST_V2}/fleets/geofence/${geofenceId}`;
  return fetch(url, {
    method: 'PATCH',
    body: JSON.stringify(params),
    headers: {
      'Content-Type': 'application/JSON',
      Authorization: `Bearer ${jwt}`,
    },
  }).then((response) => {
    if (response.ok) {
      return response.json();
    } else {
      throw new Error('There was a problem with your request. Please try again later');
    }
  });
};

export const patchVehicle = (jwt, vehicleHash, params) => {
  const url = `${API_HOST_V2}/vehicles/${vehicleHash}`;
  return fetch(url, {
    method: 'PATCH',
    headers: {
      'Content-Type': 'application/JSON',
      Authorization: `Bearer ${jwt}`,
    },
    body: JSON.stringify(params),
  }).then((response) => {
    if (response.ok) {
      return response.json();
    } else {
      throw new Error('There was a problem with your request. Please try again later');
    }
  });
};

export const getUserActiveRide = (jwt, fleetId, userId) => {

  const url = `${API_HOST_V2}/fleets/${fleetId}/users/${userId}/active-ride`;
  return fetch(url, {
    method: 'GET',
    headers: {
      Authorization: `Bearer ${jwt}`,
    },
  }).then((response) => {
    if (response.ok) {
      return response.json();
    } else {
      throw new Error('There was a problem with your request. Please try again later');
    }
  });
};

export const getDashboard = (jwt, fleetId) => {
  const url = `${API_HOST_V2}/fleets/${fleetId}/dashboard`;
  return fetch(url, {
    method: 'GET',
    headers: {
      Authorization: `Bearer ${jwt}`,
    },
  }).then((response) => {
    if (response.ok) {
      return response.json();
    } else {
      throw new Error('There was a problem with your request. Please try again later');
    }
  });
};



export const getFleetRides = (jwt, fleetId) => {
  const url = `${API_HOST_V2}/fleets/${fleetId}/rides`;
  return fetch(url, {
    method: 'GET',
    headers: {
      Authorization: `Bearer ${jwt}`,
    },
  }).then((response) => {
    if (response.ok) {
      return response.json();
    } else {
      throw new Error('There was a problem with your request. Please try again later');
    }
  });
};

export const getFleetActiveRides = (jwt, fleetId) => {
  const url = `${API_HOST_V2}/fleets/${fleetId}/active-rides`;
  return fetch(url, {
    method: 'GET',
    headers: {
      Authorization: `Bearer ${jwt}`,
    },
  }).then((response) => {
    if (response.ok) {
      return response.json();
    } else {
      throw new Error('There was a problem with your request. Please try again later');
    }
  });
};

export const getUserRides = (jwt, fleetId, userId) => {
  const url = `${API_HOST_V2}/fleets/${fleetId}/users/${userId}/rides`;
  return fetch(url, {
    method: 'GET',
    headers: {
      Authorization: `Bearer ${jwt}`,
    },
  }).then((response) => {
    if (response.ok) {
      return response.json();
    } else {
      throw new Error('There was a problem with your request. Please try again later');
    }
  });
};

export const getMapRides = (jwt, fleetId, date) => {
  const url = `${API_HOST_V2}/fleets/${fleetId}/map-rides${date ? `?date=${date}` : ''}`;
  return fetch(url, {
    method: 'GET',
    headers: {
      Authorization: `Bearer ${jwt}`,
    },
  }).then((response) => {
    if (response.ok) {
      return response.json();
    } else {
      throw new Error('There was a problem with your request. Please try again later');
    }
  });
};

export const toggleUserActive = (jwt, fleetId, userId, isActive) => {
  const body = JSON.stringify({ is_active: isActive });
  const url = `${API_HOST_V2}/fleets/${fleetId}/users/${userId}`;
  return fetch(url, {
    method: 'PATCH',
    headers: {
      Authorization: `Bearer ${jwt}`,
      'Content-Type': 'application/JSON',
    },
    body,
  }).then((response) => {
    if (response.ok) {
      return response.json();
    } else {
      throw new Error('There was a problem with your request. Please try again later');
    }
  });
};

export const toggleGroupRides = (jwt, fleetId, userId, hasGroupRides) => {
  const body = JSON.stringify({ has_group_rides: hasGroupRides });
  const url = `${API_HOST_V2}/fleets/${fleetId}/users/${userId}`;
  return fetch(url, {
    method: 'PATCH',
    headers: {
      Authorization: `Bearer ${jwt}`,
      'Content-Type': 'application/JSON',
    },
    body,
  }).then((response) => {
    if (response.ok) {
      return response.json();
    } else {
      throw new Error('There was a problem with your request. Please try again later');
    }
  });
};

export const getAdmins = (jwt, fleetId) => {
  const url = `${API_HOST_V2}/fleets/${fleetId}/admins`;
  return fetch(url, {
    method: 'GET',
    headers: {
      Authorization: `Bearer ${jwt}`,
    },
  }).then((response) => {
    if (response.ok) {
      return response.json();
    } else {
      throw new Error('There was a problem with your request. Please try again later');
    }
  });
};

export const getAdminAccountTypes = () => {
  const url = `${API_HOST_V2}/fleets/admin-account-types`;
  return fetch(url, {
    method: 'GET',
  }).then((response) => {
    if (response.ok) {
      return response.json();
    } else {
      throw new Error('There was a problem with your request. Please try again later');
    }
  });
};

export const postAdmin = (jwt, fleetId, newAdmin) => {
  const body = JSON.stringify({
    first_name: newAdmin.firstName,
    last_name: newAdmin.lastName,
    password: newAdmin.password,
    email: newAdmin.email,
  });
  const url = `${API_HOST_V2}/fleets/${fleetId}/admins`;
  return fetch(url, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/JSON',
      Authorization: `Bearer ${jwt}`,
    },
    body,
  }).then((response) => {
    if (response.ok) {
      return response.json();
    } else {
      throw new Error('There was a problem with your request. Please try again later');
    }
  });
};

export const getFleetFeatures = (jwt, fleet_id) => {
  const url = `${API_HOST_V3}/features?fleet_id=${fleet_id}&ignore_cache=true`;
  return fetch(url, {
    method: 'GET',
    headers: {
      Authorization: `Bearer ${jwt}`,
    },
  }).then((response) => {
    if (response.ok) {
      return response.json();
    } else {
      throw new Error('There was a problem with your request. Please try again later');
    }
  });
};

export const getVehicleFares = (jwt, fleet_id, vehicle_type) => {
  const url = `${API_HOST_V2}/fleets/${fleet_id}/vehicle/fares?vehicle_type=${vehicle_type}`;
  return fetch(url, {
    method: 'GET',
    headers: {
      Authorization: `Bearer ${jwt}`,
    },
  }).then((response) => {
    if (response.ok) {
      return response.json();
    } else {
      throw new Error('There was a problem with your request. Please try again later');
    }
  });
};

export const endRide = (jwt, rideId, params) => {
  const url = `${API_HOST_V2}/fleets/rides/${rideId}/stop`;
  return fetch(url, {
    method: 'POST',
    body: JSON.stringify(params),
    headers: {
      'Content-Type': 'application/JSON',
      Authorization: `Bearer ${jwt}`,
    },
  }).then((response) => {
    if (response.ok) {
      return response.json();
    }
  });
};

export const startRide = (jwt, params) => {
  const url = `${API_HOST_V2}/fleets/start-ride`;
  return fetch(url, {
    method: 'POST',
    body: JSON.stringify(params),
    headers: {
      'Content-Type': 'application/JSON',
      Authorization: `Bearer ${jwt}`,
    },
  }).then((response) => {
    if (response.ok) {
      return response.json();
    }
  });
};


export const refundRide = (jwt, ride_id, params) => {
  const url = `${API_HOST_V2}/fleets/rides/${ride_id}/refund`;
  return fetch(url, {
    method: 'POST',
    body: JSON.stringify(params),
    headers: {
      'Content-Type': 'application/JSON',
      Authorization: `Bearer ${jwt}`,
    },
  }).then((response) => {
    if (response.ok) {
      return response.json();
    }
  });
};

export const refundPartial = (jwt, ride_id, params) => {
  const url = `${API_HOST_V2}/fleets/rides/${ride_id}/refund-partial`;
  return fetch(url, {
    method: 'POST',
    body: JSON.stringify(params),
    headers: {
      'Content-Type': 'application/JSON',
      Authorization: `Bearer ${jwt}`,
    },
  }).then((response) => {
    if (response.ok) {
      return response.json();
    }
  });
};

export const refundPartialReservation = (jwt, reservation_id, params) => {
  const url = `${API_HOST_V3}/fleet-admins/reservations/${reservation_id}/partial-refund`;
  return fetch(url, {
    method: 'POST',
    body: JSON.stringify(params),
    headers: {
      'Content-Type': 'application/JSON',
      Authorization: `Bearer ${jwt}`,
    },
  }).then((response) => {
    if (response.ok) {
      return response.json();
    }
  });
};

export const cancelFleetReservation = (jwt, reservationId, params) => {
  const url = `${API_HOST_V3}/fleet-admins/reservations/${reservationId}/cancel`;
  return fetch(url, {
    method: 'POST',
    body: JSON.stringify(params),
    headers: {
      'Content-Type': 'application/JSON',
      Authorization: `Bearer ${jwt}`,
    },
  }).then((response) => {
    if (response.ok) {
      return true;
    } else {
      throw new Error('There was a problem with your request. Please try again later');
    }
  });
};

export const activateFleetReservation = (jwt, reservationId, params) => {
  const url = `${API_HOST_V3}/fleet-admins/reservations/${reservationId}/activate/`;
  return fetch(url, {
    method: 'POST',
    body: JSON.stringify(params),
    headers: {
      'Content-Type': 'application/JSON',
      Authorization: `Bearer ${jwt}`,
    },
  }).then((response) => {
    if (response.ok) {
      return true;
    } else {
      throw new Error('There was a problem with your request. Please try again later');
    }
  });
};

export const refundReservation = (jwt, reservationId, params) => {
  const url = `${API_HOST_V3}/fleet-admins/reservations/${reservationId}/refund`;
  return fetch(url, {
    method: 'POST',
    body: JSON.stringify(params),
    headers: {
      Authorization: `Bearer ${jwt}`,
    },
  }).then(response => {
    if (!response.ok) {
      throw new Error('-----There was a problem with your request. Please try again later');
    }
    return response.json(); // Assuming the response is JSON
  });
};

export const getWaiverPdfContent = async (jwt, waiverId) => {
  const url = `${API_HOST_V2}/users/waiver/${waiverId}?pdf=true`;
  try {
    const response = await fetch(url, {
      method: 'GET',
      headers: {
        Authorization: `Bearer ${jwt}`,
      },
    });
    if (!response.ok) {
      throw new Error('There was a problem with your request. Please try again later.');
    }
    const data = await response.json();
    const pdfContent = data.pdfContent;
    return pdfContent;
  } catch (error) {
    throw new Error(error.message);
  }
};



export const getPasskeys = (jwt, fleetId) => {
  const url = `${API_HOST_V2}/fleets/${fleetId}/passkeys`;
  return fetch(url, {
    headers: {
      'Content-Type': 'application/JSON',
      Authorization: `Bearer ${jwt}`,
    },
  })
  .then((response) => {
      if (response.ok) {
        let result = response.json();
        return result;
      } else {
        throw new Error('There was a problem with your request. Please try again later');
      }
    })
    .catch((error) => {
      console.log(error);
      throw error;
    });
};

export const patchPasskey = (jwt, fleetId, passkeyId, newParams) => {
  const url = `${API_HOST_V2}/fleets/${fleetId}/passkeys/${passkeyId}`;
  return fetch(url, {
    method: 'PATCH',
    headers: {
      'Content-Type': 'application/JSON',
      Authorization: `Bearer ${jwt}`,
    },
    body: JSON.stringify(newParams),
  })
    .then((response) => {
      if (response.ok) {
        return response.json();
      } else if (response.status === 409) {
        throw new Error('This Phone number is already being used by another user');
      } else {
        throw new Error('There was a problem with your request. Please try again later');
      }
    })
    .catch((error) => {
      console.log(error);
      throw error;
    });
};

export const addNewPasskey = (jwt, fleetId, passkeyData) => {
  const url = `${API_HOST_V2}/fleets/${fleetId}/passkeys`;
  const body = {
    passkey: passkeyData.passkey,
    metadata: passkeyData.metadata,
    is_active: passkeyData.is_active,
  };

  return fetch(url, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/JSON',
      Authorization: `Bearer ${jwt}`,
    },
    body: JSON.stringify(body),
  }).then((response) => {
    return response.json().then((json) => {
      if (response.ok) {
        return { success: true };
      } else {
        return { success: false, error: json.message || 'Unknown error occurred' };
      }
    });
  });
};

export const deletePasskey = (jwt, fleetId, passkeyId) => {
  const url = `${API_HOST_V2}/fleets/${fleetId}/passkeys/${passkeyId}`;
  return fetch(url, {
    method: 'DELETE',
    headers: {
      'Content-Type': 'application/JSON',
      Authorization: `Bearer ${jwt}`,
    },
  }).then((response) => {
    if (response.ok) {
      return { success: true };
    } else {
      return response.json().then((json) => ({ success: false, error: json }));
    }
  });
};

export const togglePasskeyActive = (jwt, fleetId, passkeyId, isActive) => {
  const body = JSON.stringify({ is_active: isActive });
  const url = `${API_HOST_V2}/fleets/${fleetId}/passkeys/${passkeyId}`;
  return fetch(url, {
    method: 'PATCH',
    headers: {
      Authorization: `Bearer ${jwt}`,
      'Content-Type': 'application/JSON',
    },
    body,
  }).then((response) => {
    if (response.ok) {
      return response.json();
    } else {
      throw new Error('There was a problem with your request. Please try again later');
    }
  });
};





