//import wrapWithProvider from './src/wrap-with-provider';
//export const wrapRootElement = wrapWithProvider;
import React from 'react';
import { ThemeProvider } from '@mui/material/styles';
import CssBaseline from '@mui/material/CssBaseline';
import theme from './src/theme';
import { HeaderHeightProvider } from './src/context/HeaderHeightContext';

export const wrapRootElement = ({ element }) => (
    <ThemeProvider theme={theme}>
      <HeaderHeightProvider>
        <CssBaseline />
        {element}
      </HeaderHeightProvider>
    </ThemeProvider>
  );
