import * as api from '../api';


const GET_WAIVERS = 'waivers/GET_WAIVERS';
const APPROVE_WAIVER = 'waivers/APPROVE_WAIVERS';
const initialState = null;

export default function reducer(state = initialState, action) {
  switch (action.type) {
    case GET_WAIVERS:
      return action.data;
    case APPROVE_WAIVER:
      return state;
    default:
      return state;
  }
}

export const getWaivers = (jwt, operatorId) => {
  return (dispatch) => {
    return api
      .getWaivers(jwt, operatorId)
      .then((response) => {
        const waivers = response.waivers;
        if (waivers) {
          dispatch({ type: GET_WAIVERS, data: waivers });
        } else {
          throw new Error('There was a problem getting waivers');
        }
      });
  };
};


export const updateWaiverStatus = (jwt, waiverId, waiverStatus) => {
  return (dispatch) => {
    return api
      .updateWaiverStatus(jwt, waiverId, waiverStatus)
      .then((response) => {
        dispatch({ type: APPROVE_WAIVER});
      });
  };
};

