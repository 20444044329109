import * as api from '../api';
import { PURGE } from 'redux-persist';

const moment = require('moment-timezone');
const dateFormat = 'MMMM D, YYYY h:mm a';

const GET_FLEET_RIDES = 'ride/GET_FLEET_RIDES';
const initialState = [];

export default function reducer(state = initialState, action) {
  switch (action.type) {
    case GET_FLEET_RIDES:
      return action.data.map((fleet_ride) => {
        const formattedStart = moment(fleet_ride.start_time).format(dateFormat);
        const formattedEnd = moment(fleet_ride.end_time).format(dateFormat);
        const formattedDuration = `${Math.round(fleet_ride.duration / 60)} min`;
        return {
          ...fleet_ride,
          formatted_start_time: formattedStart,
          formatted_end_time: formattedEnd,
          formatted_duration: formattedDuration,
        };
      });
    case PURGE:
      return initialState;
    default:
      return state;
  }
}

export const getFleetRides = (jwt, fleetId) => {
  return (dispatch) => {
    return api
      .getFleetRides(jwt, fleetId)
      .then(({ fleet_rides }) => {
        dispatch({ type: GET_FLEET_RIDES, data: fleet_rides })});
  };
};






