import * as api from '../api';
import { PURGE } from 'redux-persist';

const moment = require('moment-timezone');
const dateFormat = 'MMMM D, YYYY h:mm a';

const GET_FLEET_ACTIVE_RIDES = 'fleet_active_rides/GET_FLEET_ACTIVE_RIDES';
const initialState = [];


export default function reducer(state = initialState, action) {
  switch (action.type) {
    case GET_FLEET_ACTIVE_RIDES:
      return action.data.map((active_ride) => {
        const formattedStart = moment(active_ride.start_time).format(dateFormat);
        const formattedEnd = moment(active_ride.end_time).format(dateFormat);
        const formattedDuration = `${Math.round(active_ride.duration / 60)} min`;
        return {
          ...active_ride,
          formatted_start_time: formattedStart,
          formatted_end_time: formattedEnd,
          formatted_duration: formattedDuration,
        };
      });
    case PURGE:
      return initialState;
    default:
      return state;
  }
}

export const getFleetActiveRides = (jwt, fleetId) => {
  return (dispatch) => {
    return api
      .getFleetActiveRides(jwt, fleetId)
      .then(({ fleet_active_rides }) => {
        dispatch({ type: GET_FLEET_ACTIVE_RIDES, data: fleet_active_rides })});
  };
};






